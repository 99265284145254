import { Button, Pagination, PaginationProps } from "antd";
import { ReactNode } from "react";

interface Props extends PaginationProps {
  pathPrefix: string;
}

const MyPagination = (props: Props) => {
  const itemRender = (page: number, type: string, originalElemen: ReactNode) => {
    const href = `/${props.pathPrefix}/${page}`
    if (type === "page") {
      // 在翻页按钮上添加<a>便于nextjs导出静态页面
      return (
        <a href={href} rel={href}>
          {page}
        </a>
      );
    }
    if (type === "prev") {
      return (
        <Button>
          <a href={href} rel={href}>
            上一页
          </a>
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button>
          <a href={href} rel={href} target="_self">
            下一页
          </a>
        </Button>
      );
    }
    return originalElemen;
  };
  return (
    <Pagination
      {...props}
      itemRender={itemRender}
      // size="small"
    />
  );
};

export default MyPagination;
