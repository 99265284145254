import Link from "next/link";
import { memo } from "react";
import { LatestArticlesProps } from "./types";
import style from "./style.module.scss";

const Com = (props: LatestArticlesProps): JSX.Element => {
  const { list = [] } = props;
  return (
    <div className={style["latest-article"]}>
      <div className={style["latest-article-header"]}>
        <strong>最新文章</strong>
      </div>
      <div className={style["latest-article-list"]}>
        {list.map((article) => (
          <Link
            key={article.id}
            href={`/blog/${article.id}`}
            passHref
            className={style["article"]}
            title={article.name}
            target="_blank"
          >
            {article.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export const LatestArticles = memo(Com);
